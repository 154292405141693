<template>
  <div>
    {{ parametre }}
  </div>
</template>
        
        <script>
        import {mapGetters } from 'vuex'
        export default {
            props: {object: {type: Object, required: true}},
            data(){
                return{
        
                }
            },
            methods: {
               
            },
            computed: {
                ...mapGetters({
                    parametres: 'sanitaire/ficheParametres',
                }),
                parametre(){
                  let c = this.parametres.find(item => item.uid === this.object.parametre)
                  if(c) return c.libelle
                  return '-'
                }
            }
        }
        </script>
        
        <style>
        
        </style>